import React, { useEffect, useState } from 'react';
import { IFile } from '../../types';
import api from '../../services/api';
import { onDownloadFile } from '../../utils/helpers';
import './style.css';
import { useDebounce } from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const supportedPreviews = ['jpg', 'png', 'pdf', 'svg'];

interface PreviewFileProps {
  file: IFile;
}

const PreviewFile: React.FC<PreviewFileProps> = ({ file }) => {
  const { t } = useTranslation('common');
  const [hoverFileLink, setHoverFileLink] = useState<string>('');
  const [hoverNow, setHoverNow] = useState(false);
  const debounced = useDebounce(hoverNow, 200);

  const onHoverImg = () => {
    return <img src={hoverFileLink} className="preview-file__img" alt="" />;
  };

  const onHoverPdf = () => {
    return <embed src={hoverFileLink} className="preview-file__pdf" />;
  };

  const renderPreview = () => {
    if (debounced && supportedPreviews.includes(file.extension)) {
      if (file.extension === 'pdf') {
        return onHoverPdf();
      } else {
        return onHoverImg();
      }
    }
    return null;
  };

  const onMouseEnter = () => {
    setHoverNow(true);
  };

  const onLeaveHover = () => {
    setHoverNow(false);
    setHoverFileLink('');
  };

  useEffect(() => {
    if (debounced && !hoverFileLink && supportedPreviews.includes(file.extension)) {
      const fetchLinkAsync = async (fileId: number) => {
        try {
          const result = await api.files.get(fileId);
          let fileLink: string = '';
          if (file.extension === 'pdf') {
            fileLink = URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
          } else if (file.extension === 'svg') {
            fileLink = URL.createObjectURL(new Blob([result.data], { type: 'image/svg+xml' }));
          } else {
            fileLink = URL.createObjectURL(new Blob([result.data]));
          }
          setHoverFileLink(fileLink);
        } catch (error) {
          console.error("Error fetching file", error);
        }
      };
      fetchLinkAsync(file.id);
    }
  }, [debounced, file.id, file.extension, hoverFileLink]);

  return (
    <div
      id="preview-file"
      className="preview-file"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onLeaveHover}
    >
      <Link
        to={`/files/${file.id}`}
        state={{ myData: file }}
        className="preview-file__link"
      >
        {file.original_name}
      </Link>
      {renderPreview()}
    </div>
  );
};

export default PreviewFile;
