import React, { useState,useEffect } from 'react';
import { IComment } from '../../store/slices/task/types';
import editImage from '../../assets/images/icons/edit.svg';
import deleteImage from '../../assets/images/icons/wastebasket.svg';
import quoteImage from '../../assets/images/icons/link.svg';
import './style.css';
import parse from 'html-react-parser';
import { CommentEditor } from '../detailTabs/CommentsTab/CommentEditor';
import Avatar from '../avatar';
import FileList from '../files/fileList';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import api from '../../services/api';
import { restoreCustomerComment, restoreTeamComment } from '../../store/slices/task/comments';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../types';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';
import {parseUrl, ParsedUrl} from '../../utils/helpers/urlHelper';


interface ICommentProps {
  comment: IComment;
  pinnedStatus: boolean;
  onDelete: (commentId: number) => void;
  published: string;
}

export const CommentCard: React.FC<ICommentProps> = ({
  comment,
  pinnedStatus,
  onDelete,
  published,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { id: userId } = useAppSelector((state) => state.currentUser);
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  useEffect(() => {
    scrollToComment(); // Автопрокрутка при монтировании компонента
  }, []);

  const onEdit = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const handleRestoreComment = async () => {
    try {
      await api.comments.restoreComment(comment.id, published);
      if (published === '1') {
        dispatch(restoreTeamComment(comment.id));
      } else {
        dispatch(restoreCustomerComment(comment.id));
      }
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (error.response && error.response.data.error_message) {
        alert(error.response.data.error_message);
      }
    }
  };
  const copyLinkToClipboard = () => {

    const baseUrl = "https://ez.zennex.ru/tasks";

    const { taskId, hash } = parseUrl();

    const resolvedTaskId = taskId || "defaultTaskId";
    const pid = comment.id || "defaultPid";
    const commentType = hash || "defaultCommentType";

    // Формируем ссылку
    const link = `${baseUrl}/${resolvedTaskId}?pid=${pid}#${commentType}`;
    
    // Копируем ссылку в буфер обмена
  navigator.clipboard
  .writeText(link)
  .then(() => {
    showToast("Ссылка скопирована!");
  })
  .catch((err) => {
    console.error("Не удалось скопировать ссылку: ", err);
  });
};
const showToast = (message: string) => {
  // Создаем элемент всплывающего сообщения
  const toast = document.createElement("div");
  toast.textContent = message;

 // Стили для всплывающего сообщения
toast.style.position = "fixed";
toast.style.bottom = "20px"; // Отступ от нижнего края
toast.style.left = "80px"; // Отступ от левого края
toast.style.transform = "translate(0, 0)"; // Убираем центрирование, ставим в левый нижний угол
toast.style.backgroundColor = "#1687C9"; // Светло-голубой фон
toast.style.color = "#fff"; // Белый текст
toast.style.padding = "15px 30px"; // Внутренние отступы
toast.style.borderRadius = "8px"; // Закругленные углы
toast.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)"; // Тень
toast.style.fontSize = "16px";
toast.style.fontWeight = "bold";
toast.style.textAlign = "center"; // Центровка текста
toast.style.opacity = "1";
toast.style.transition = "opacity 0.5s ease";

document.body.appendChild(toast);

  // Удаляем сообщение через 3 секунды
  setTimeout(() => {
    toast.style.opacity = "0"; // Плавное исчезновение
    setTimeout(() => {
      toast.remove();
    }, 500); // Удаляем из DOM через 0.5 сек после исчезновения
  }, 1000);
};


const scrollToComment = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const pid = urlParams.get("pid");

  if (pid) {
      const commentElement = document.querySelector(`[data-id="${pid}"]`);
      if (commentElement) {
          commentElement.scrollIntoView({ behavior: "smooth", block: "center" });
          commentElement.classList.add("highlight");
          setTimeout(() => commentElement.classList.remove("highlight"), 2000);
      }
  }
};
  if (comment.deleted) {
    return (
      <div className="comment-card comment-card_deleted">
        <div className="comment-card__message">{t('comments.commentDeleted')}</div>
        <Button onClick={handleRestoreComment} className="comment-card__restore">
          {t('comments.restore')}
        </Button>
      </div>
    );
  }

  return isEditorOpen ? (
    <CommentEditor published={published} comment={comment} closeEditorHandler={onEdit} />
  ) : (
    <div className={`comment-card ${pinnedStatus ? 'pinned' : ''}`} data-id={comment.id}>
      <div className="card-person">
        <Avatar />
        <div>
          <p className="person-name">{comment.owner.name}</p>
        </div>
        <p className="person-created">{comment.created} </p>
        <div className="action-icons">
          <PermissionsGate
            permissions={
              comment.owner.id === userId
                ? [Permissions.create_posts]
                : [Permissions.delete_other_users_posts]
            }
          >
            <img
              src={deleteImage}
              alt=""
              className="action-image "
              onClick={() => onDelete(comment.id)}
            />
          </PermissionsGate>

          <PermissionsGate
            permissions={
              comment.owner.id === userId
                ? [Permissions.create_posts]
                : [Permissions.update_other_users_posts]
            }
          >
            <img src={editImage} className=" action-image" alt="" onClick={() => onEdit()} />
          </PermissionsGate>

          <img
            src={quoteImage}
            alt=""
            className="action-image"
            onClick={copyLinkToClipboard}
          />
        </div>
      </div>
      <div className="comment-content">
        <div className="comment-text">{parse(comment.message)}</div>
        {comment.files.length ? (
          <div className="comment-files">
            <hr />
            <FileList files={comment.files} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};