export interface ParsedUrl {
    protocol: string;
    host: string;
    path: string;
    hash: string;
    taskId?: string;
    params: Record<string, string>;
}
/**
 * Разбирает URL и возвращает объект с частями URL.
 * @param url - URL для разбора. Если не передан, используется текущий URL из window.location.
 * @returns ParsedUrl - Объект с частями URL.
 */
export const parseUrl = (url?: string): ParsedUrl => {
    const parsedUrl = new URL(url || window.location.href); // Если URL не передан, берем текущий
    const pathSegments = parsedUrl.pathname.split("/"); // Разбиваем путь на части
    const urlParams = new URLSearchParams(parsedUrl.search); // Параметры запроса

    return {
        protocol: parsedUrl.protocol, // Протокол (http: или https:)
        host: parsedUrl.host, // Хост (например, localhost:3000)
        path: parsedUrl.pathname, // Путь (например, /tasks/25826)
        hash: parsedUrl.hash.slice(1), // Хэш (без #)
        taskId: pathSegments[2], // Предполагаем, что ID задачи — 3-й элемент пути
        params: Object.fromEntries(urlParams) as Record<string, string>, // Все параметры в виде объекта
    };
};
